<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <RoomForm></RoomForm>
          
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
            <div class="vx-card p-6 mb-6">
              <RoomImage></RoomImage>
            </div>
        </div>
       </div>
       <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <AdditionalInfo></AdditionalInfo>
          
          </div>
        </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <SettingInfo></SettingInfo>
 
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import RoomForm from './common/RoomForm';
import SettingInfo from './common/SettingInfo';
import RoomImage from './common/RoomImage';
import AdditionalInfo from './common/AdditionalInfo';
import {HOMESTAY_ROOM} from '../../../../constant/entity-identifier'
import { mapGetters, mapActions } from 'vuex'

import {storeImage} from '../../../../services/image';
import loaderMixin from '../../../../mixins/loaderMixin';

export default {
  components: {
    vSelect,
    RoomForm,
    SettingInfo,
    AdditionalInfo,
    RoomImage
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        roomForm: 'hotel/getRoomFormObj',
        roomFormDropwown: 'hotel/getRoomFormObjDropDown',
        roomImages: 'hotel/getHotelRoomImages',
    }),
    getHotelId() {
        return this.$route.params.hotelId;
    },
  },
  created(){
      this.clearRoomForm();
      this.clearValidationErrors();
    },
  methods: {
    ...mapActions({
      createHotelRoom: 'hotel/createHotelRoom',
      clearRoomForm: 'hotel/clearRoomForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    prepareForm(id, type) {
      let formData = new FormData();
      formData.append("entity_id", id);
      formData.append("entity", type);
      formData.append("images[0]", "");
      for (let i = 0; i < this.roomImages.length; i++) {
          formData.append("images[" + i + "]", this.roomImages[i].image);
          formData.append("alt[" + i + "]", this.roomImages[i].alt);
      }
      return formData;
    },
    submitForm() {
      this.openLoading();
      this.createHotelRoom({hotelId: this.getHotelId, data: this.roomForm})
        .then(async response => {
           if(response.status === 201) {
             if(this.roomForm.images.length > 0) {
                await storeImage(this.prepareForm(response.data.data.id, HOMESTAY_ROOM));
                this.closeLoading();
             }
            this.$vs.notify({ 
                title: 'Hotel Room Created',
                text: 'Hotel Room created successfully.',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push("/hotels/" + this.getHotelId + "/view");
           }
        }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
