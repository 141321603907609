<template>
  <div>
    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
    <span class="leading-none font-medium">Room Images</span>
    <vs-button icon="save" type="border" size="small" @click="cloneRoomImage" class="plus-icons" color="primary"> Add More Image</vs-button>
    <div v-for="(image, index) in roomImages" :key="index" class="images-comp">
      <file-pond
          name="test"
          ref="pond"
          label-idle="Drop files here..."
          :allow-multiple="false"
          instantUpload="false"  
          allowImagePreview="true"
          allowImageSizeMetadata="true"
          allowFileMetadata="true"
          :fileMetadataObject="{index: index}"
          accepted-file-types="image/jpeg, image/png"
          :allowImageTransform="false"
          :files="image.source[0].source != '' ? image.source[0].source : ''"
          :onremovefile="confirmDeleteUploadImage"
          @addfile="handleRoomFileChange"/>
          <vs-input 
          class="w-full mt-4" 
          label="Alt Text" 
          :danger="validation.alt?true:false"
          :danger-text="validation.alt?validation.alt[0]:''"
          :value="image.alt"
          @input="updateAltForm({key: 'alt', value: $event, index: index})" />   
        <div class="demo-alignment">
          <vs-button icon="delete" type="border" size="small" @click="confirmRemoveClonedRoomImage(index)" class="cross-icons" v-if="showRemoveBtn && index != 0" color="danger">Remove</vs-button> 
      </div>     
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

import vueFilePond from 'vue-filepond';
// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileMetadata );

export default {
  components: {
    FilePond
  },
  data: () => {
    return{
      imageId: '',
      showRemoveBtn: false,
      imageIndex: '',
    }
  },
  computed: {
    ...mapGetters({
        roomFormDropDown: 'hotel/getRoomFormObjDropDown',
        roomImages: 'hotel/getHotelRoomImages',
        validation: 'validation/getValidationErrors'
    })
  },
  methods: {
    ...mapActions({
      deleteHotelImageAction: 'hotel/deleteHotelImageAction',
      fetchAndSetHotelById: 'hotel/fetchAndSetHotelById',
      updateRoomForm: 'hotel/updateRoomForm'
    }),
    handleRoomFileChange(image_name, files) {
      var index = files.getMetadata('index');
      this.updateRoomForm({key: 'images', value:{index: index, value: files.file}});
    },

    updateAltForm(alt){
      this.updateRoomForm({key: 'alt', value:{index: alt.index, value: alt.value}});
    },

    cloneRoomImage(){
        this.updateRoomForm({key:{key: "addImage", index: 0}, value: {image: "", alt: "", source:[{source: null, options: {type: '', file:''}
            }]}});
        this.showRemoveBtn = this.roomImages.length > 1 ? true : false;
    },

    removeRoomImage(){
      this.updateRoomForm({key:{key: "RemoveImage", index: this.imageIndex}, value: {image: "", alt: "", source:[{source: null, options: {type: '', file:''}
            }]}});
      this.showRemoveBtn = this.roomImages.length > 1 ? true : false;
    },

    confirmRemoveClonedRoomImage(index){
      this.imageIndex = index;
        this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Remove`,
        text: `You are about to remove image`,
        accept: this.removeRoomImage,
        acceptText: "Remove"
      })
    },

    removeImage(){
      this.$vs.notify({ 
            text: 'Hotel Images Removed successfully.',
            title: 'Hotel Images Removed',
            color: 'success',
            position: 'top-right'
        });
    },

    confirmDeleteUploadImage(image_name, files) {
      this.imageId = files.file.id;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete image with id: "${files.file.id}"`,
        accept: this.deleteUploadImage,
        acceptText: "Delete"
      })
    },

    editHotelRecord(){
      this.fetchAndSetHotelById(this.form.hotelId).catch((r) => console.log(r));
    },

    deleteUploadImage(){
      this.deleteHotelImageAction(this.imageId).then((response) => {
      if(response.status === 200){
        this.editHotelRecord();
        this.$vs.notify({ 
              text: 'Hotel Images Deleted successfully.',
              title: 'Hotel Images Deleted',
              color: 'success',
              position: 'top-right'
        });
      }
        });
    },
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  
  .plus-icons{
    float: right;
    cursor: pointer;
    color: #0c78e7;
  }
  .images-comp{
    margin-top: 35px;
  }
}
</style>
