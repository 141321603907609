<template>
  <div>
     <div>
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Additional Info</span>
    </div>
    <vs-input 
        class="w-full mt-4" 
        label="Price" 
        :danger="validation.price?true:false"
        :danger-text="validation.price?validation.price[0]:''"
        :value="roomForm.price"
        @input="updateRoomForm({key: 'price', value: $event})" />

    <vs-input 
        class="w-full mt-4" 
        label="Extra Charge" 
        :danger="validation.extra_charge?true:false"
        :danger-text="validation.extra_charge?validation.extra_charge[0]:''"
        :value="roomForm.extra_charge"
        @input="updateRoomForm({key: 'extra_charge', value: $event})" />

    <vs-input 
          class="w-full mt-4" 
          label="Room Area" 
          :danger="validation.room_area?true:false"
          :danger-text="validation.room_area?validation.room_area[0]:''"
          :value="roomForm.room_area"
          @input="updateRoomForm({key: 'room_area', value: $event})" />

    <vs-input 
          class="w-full mt-4" 
          label="Bathroom Area" 
          :danger="validation.bathroom_area?true:false"
          :danger-text="validation.bathroom_area?validation.bathroom_area[0]:''"
          :value="roomForm.bathroom_area"
          @input="updateRoomForm({key: 'bathroom_area', value: $event})" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {

    }
  },

  mounted() {
  },
  computed: {
    ...mapGetters({
        roomForm: 'hotel/getRoomFormObj',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions({
      updateRoomForm: 'hotel/updateRoomForm',
    })
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
